import { useEffect, useState } from 'react';
import { Box } from 'rebass';
import { Label, Checkbox as RebassCheckbox } from '@rebass/forms';

import useCart from 'hooks/usecart';

export default function Checkbox({ galleryId, photoId, inverted }) {
  const [selected, setSelected] = useState(false);
  const [cart, dispatch] = useCart();

  useEffect(() => {
    if (cart && photoId) {
      const isSelected =
        cart.selection.find((s) => s.id === photoId) !== undefined;
      setSelected(isSelected);
    }
  }, [cart, photoId]);

  const handleSelect = (e) => {
    if (e.target.checked) {
      dispatch({ type: 'add', id: photoId, galleryId: galleryId });
    } else {
      dispatch({ type: 'remove', id: photoId });
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: inverted ? 'transparent' : 'white',
        margin: 2,
        borderRadius: 4,
      }}
    >
      <Label>
        <RebassCheckbox
          checked={selected}
          mr={0}
          onChange={handleSelect}
          sx={{
            color: inverted ? 'white' : null,
          }}
        />
      </Label>
    </Box>
  );
}
