import React from 'react';
import { Box, Heading } from 'rebass';

import Avatar from 'components/avatar';
import mask from 'images/banner.svg';
import Photo from 'components/photo';

function Banner({ small, studio, banner, title, description, absolute }) {
  const height = small ? 42 : 64;
  const avatarSize = 150;
  return (
    <>
      <Box
        sx={{
          position: absolute ? 'absolute' : 'relative',
          ...(absolute ? { top: 0, left: 0, right: 0, zIndex: '-100' } : {}),
          marginBottom: 5,
        }}
      >
        <Box
          sx={{
            mask: `url(${mask}) 27% bottom no-repeat`,
            height: absolute ? ['100vh', `calc(50vh + 200px)`] : `${height}vh`,
            transition: 'height 0.1s ease-in-out',
            backgroundColor: 'brand',
          }}
        >
          {banner && (
            <Photo
              item={banner}
              width={banner.width}
              ratio={banner.height / banner.width}
              sizes="100vw"
              fill
            />
          )}
        </Box>
        {studio && (
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              bottom: [-50, 0],
            }}
          >
            <Avatar studio={studio} size={avatarSize} />
          </Box>
        )}
      </Box>
      {!absolute && (
        <Box
          sx={{
            paddingX: [3, 0],
            marginLeft: [0, '50%'],
            width: ['100%', '25%'],
          }}
        >
          <Heading sx={{ minHeight: '1.5em' }}>
            {title ? title : studio && studio.name}
          </Heading>
          {description && <Box fontSize={0}>{description}</Box>}
        </Box>
      )}
    </>
  );
}

export default Banner;
