import React, { useState, useEffect, useRef } from 'react';
import { Box, Image } from 'rebass';
import NextImage from 'next/image';
import Link from 'next/link';

import Checkbox from 'components/checkbox';

const DEFAULT_WIDTH = 768;

function Photo({
  item,
  width = DEFAULT_WIDTH,
  ratio,
  href,
  selectable = false,
  gallery,
  sizes,
  fill,
  ...rest
}) {
  if (!item) {
    return (
      <Box
        sx={{
          width: '100%',
          paddingBottom: `${(ratio || 2 / 3) * 100}%`,
          backgroundColor: 'brand',
        }}
      />
    );
  }

  const styleImage = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 0.3s ease-in-out',
  };

  const image = (
    <Box sx={styleImage}>
      <NextImage
        src={item.url}
        title={item.filename}
        width={fill ? null : width}
        height={
          fill ? null : Math.floor(width * (ratio || item.height / item.width))
        }
        layout={fill ? 'fill' : 'responsive'}
        objectFit="cover"
        sizes={sizes}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        paddingBottom: fill
          ? null
          : `${(ratio || item.height / item.width) * 100}%`,
      }}
    >
      <Image src={item.blur} title={item.filename} sx={styleImage} />
      {href ? (
        <Link href={href} {...rest}>
          <a>{image}</a>
        </Link>
      ) : (
        image
      )}
      {selectable && <Checkbox galleryId={gallery.id} photoId={item.id} />}
    </Box>
  );
}

export default Photo;
