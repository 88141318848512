import React from 'react';
import { Box } from 'rebass';

import Photo from 'components/photo';

function MaskedBox({ size, backgroundColor, children }) {
  return (
    <Box
      sx={{
        clipPath:
          'polygon(37% 0%, 87% 13%, 100% 62%, 62% 100%, 13% 87%, 0% 37%)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: size,
        height: size,
        backgroundColor,
      }}
    >
      {children}
    </Box>
  );
}

function Avatar({ studio, size }) {
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: size,
      }}
    >
      <MaskedBox backgroundColor="white" size={size + 4} />
      <MaskedBox backgroundColor="brand" size={size}>
        <Photo item={studio.avatar} width={size} ratio={1} href="/" />
      </MaskedBox>
    </Box>
  );
}

export default Avatar;
