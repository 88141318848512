import React from 'react';
import { Box } from 'rebass';

function Container({ children }) {
  return (
    <Box
      sx={(theme) => ({
        maxWidth: theme.breakpoints[theme.breakpoints.length - 1],
        mx: 'auto',
        paddingX: 3,
        paddingTop: [4, 6],
        paddingBottom: 4,
      })}
    >
      {children}
    </Box>
  );
}

export default Container;
